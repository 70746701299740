<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container class="mt-15 pt-0" fluid>
    <v-row class=" mt-0 pt-0">
      <v-col id="api-container" class="  pt-0" cols="12">
        <material-card color="green">
          <template v-slot:header>
            <div class="px-3 col-md-8">
              <div class="title font-weight-light mb-2">
                {{ $vuetify.lang.t(label) }}
              </div>
              <div class="category font-weight-thin">
                Source: {{ url }}
              </div>
              <div class="category font-weight-thin">
                Version: {{ version }}
              </div>
            </div>

            <div class="form col-md-4">
              <v-row class="mx-0">
                <v-text-field v-model="token" :label="$vuetify.lang.t('$vuetify.token')" class="mr-6 purple-input"
                              color="yellow" hide-details required></v-text-field>

                <div class="my-2">
                  <v-btn class="text-center" color="secondary" dark fab small @click="getSwagger">
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </div>

                <v-btn v-if="postmanCollection != null" class="ma-2 white--text" color="orange darken-5"
                       @click="downloadPostManCollection()">
                  PostMan
                  <v-icon dark right>
                    mdi-cloud-download
                  </v-icon>
                </v-btn>

              </v-row>
            </div>
          </template>

          <v-row v-if="!isTokenProvided && !error" class="warning" justify="center">
            {{ $vuetify.lang.t('$vuetify.enterAPIKey', $vuetify.lang.t(label)) }}
          </v-row>
          <v-row v-if="error" class="error" justify="center">
            {{ error }}
          </v-row>

          <v-row v-if="url && isTokenProvided && !error">
            <div id="redoc-container">
              <redoc v-pre></redoc>
            </div>
          </v-row>

        </material-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
/* eslint-disable no-undef */

import axios from 'axios'
import postmanConvertor from 'openapi-to-postmanv2'

// Get APIs List
import apiGroupsList from '@/apis.json'

export default {
  name: 'APIPage',
  data: () => ({
    label: '',
    version: '',
    url: '',
    token: '',
    error: '',
    isTokenProvided: false,
    component: null,
    valid: false,
    postmanCollection: null
  }),
  created () {
    // Get API Data when created (reading route)
    this.getAPIData()

    // Load redocScript
    const redocScript = document.createElement('script')
    redocScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/redoc@2.0.0-rc.16/bundles/redoc.standalone.js')
    document.head.appendChild(redocScript)
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  },
  methods: {
    getAPIData () {
      for (let i = 0; i < apiGroupsList.length; i++) {
        if (apiGroupsList[i].items) {
          for (let j = 0; j < apiGroupsList[i].items.length; j++) {
            const item = apiGroupsList[i].items[j]
            if (this.$route.path === item.path) {
              this.label = item.label
              this.version = item.version
              this.apiPath = item.path
              this.url = item.url
              this.isTokenProvided = false
              this.error = null
              this.token = ''
            }
          }
        }
      }
    },
    fetchData () {
      if (this.$route.path.indexOf(this.apiPath) === -1) {
        this.getAPIData()
        this.postmanCollection = null
      } else if (this.$route.hash === '') {
        this.getAPIData()
        this.postmanCollection = null
      }
    },
    getSwagger () {
      const self = this
      const testURL = this.url + '?access_token=' + this.token + '&lang=' + this.$vuetify.lang.current
      this.isTokenProvided = true
      this.postmanCollection = null

      axios.get(testURL, {
        crossdomain: true,
        headers: {
          'Content-Type': 'text/plain'
        }
      })
        .then(response => {
          this.error = null
          if (response.data !== '') {
            Redoc.init(testURL, {
              scrollYOffset: 100,
              disableSearch: true,
              suppressWarnings: true
            }, document.getElementById('redoc-container'))

            // Create PostMan collection
            postmanConvertor.convert({ type: 'string', data: response.data },
              {}, (err, conversionResult) => {
                if (!conversionResult.result) {
                  console.error('Could not convert', conversionResult.reason)
                  console.error('Err', err)
                } else {
                  self.postmanCollection = conversionResult.output[0].data
                }
              }
            )
          }
        })
        .catch(err => {
          console.error(err)
          this.isTokenProvided = false

          this.error = this.$vuetify.lang.t('$vuetify.errorGetSwagger')
        })
    },
    downloadPostManCollection () {
      const collectionFormatted = JSON.stringify(this.postmanCollection, null, '\t')
      const blob = new Blob([collectionFormatted], { type: 'application/json' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)

      const fileName = 'AP_' + this.$vuetify.lang.t(this.label) + '_PostManCollection.json'

      link.download = fileName
      link.click()
    }
  }
}
</script>

<style scoped>
.form {
  float: right;
  margin-left: auto;
}

#api-container .v-card.v-sheet.theme--light {
  margin-bottom: 33px !important;
}

div#redoc-container {
  width: 100%;
}
</style>
